& {
	font-size: 18px;
}

// heading
h1 {
	text-shadow: 6px 6px 0 rgba($black, 1);
}

h2 {
	text-shadow: 3px 3px 0 rgba($black, 1);
}

h3 {
	color: $yellow;
}

.bg-dark {
	h3 {
		color: $yellow;
	}
}

// container
.container-fluid {
	max-width: 1920px;
}

.container-narrow {
	max-width: 890px;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	background: rgba($green, 0.25);
	border-radius: 10px;
	border: none;

	.card-body {
		.card-caption {
			.card-title {
				color: $green;
			}

			.card-subtitle {
				font-size: 18px;
				font-weight: 700;
				font-family: $font-family-primary;
			}
		}
	}
}

// heading-large
.heading-large {
	@include font-size($h1-font-size);
	text-shadow: 6px 6px 0 rgba($black, 1);
}
