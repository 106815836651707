// footer
.footer {

	// footer-partners
	.footer-partners {
		margin: 30px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		.container-holder {
			align-items: flex-end;

			@include media-breakpoint-down(xs) {
				flex-direction: column-reverse;
			}

			>.column {
				&.one {
					padding-right: 15px !important;

					@include media-breakpoint-up(sm) {
						@include make-col(6);
					}
				}

				&.two {
					padding-left: 15px !important;

					@include media-breakpoint-up(sm) {
						@include make-col(6);
					}
				}
			}
		}

		.logo {
			max-width: 280px;
			margin: 0 0 -85px 0;

			@include media-breakpoint-only(sm) {
				margin: 0 0 -75px auto;
			}

			@include media-breakpoint-up(md) {
				max-width: 350px;
				margin: 0 0 -100px auto;
			}
		}

		.footer-text {
			@include media-breakpoint-down(xs) {
				max-width: 330px;
				margin-left: auto;
				text-align: right;
			}

			p {
				margin-bottom: 10px;
				color: $orange;
				font-size: 30px;
				line-height: 0.85;
				font-family: $font-family-secondary;
			}
		}
	}

	// footer-address
	.footer-address {
		padding: 100px 0 45px 0;
		background-color: $green;
		text-align: center;

		.footer-text {
			color: $white;

			a {
				color: $white;
				text-decoration: underline;
				font-family: $font-family-secondary;

				&:hover {
					color: $yellow;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-top: 15px;

			ul {
				justify-content: center;

				li {
					+li {
						margin-left: 10px;
					}

					a {
						display: block;
						width: 40px;
						height: 40px;
						background-color: $orange;
						border-radius: 100%;
						color: $white;
						font-size: 24px;
						line-height: 40px;
						text-align: center;

						&:hover {
							background-color: $yellow;
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up(md) {
				flex-direction: row;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@include media-breakpoint-up(md) {
					margin: 0 15px;
				}

				color: $green;
				font-size: 16px;

				a {
					color: $green;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

}

&:not(.home) {
	.footer {
		margin-top: 8vh;
	}
}
