// btn
.btn {
	min-width: 175px;
	padding: 6px 1.5rem;
	border-radius: 10px;
	font-size: 18px;
	font-family: $font-family-secondary;
	text-shadow: 2px 2px 0 rgba($black, 1);

	// btn-primary
	&.btn-primary {
		&:hover {
			border-color: $green;
			background-color: $green;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			border-color: $orange;
			background-color: $orange;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
