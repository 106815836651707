// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 890px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// intro-section
// =========================================================================
.intro-section {
	text-align: center;

	// jumbo-collection
	.jumbo-collection {
		margin: 30px 0;

		&.grid {
			.grid-items {
				margin-bottom: -60px;

				.item {
					@include make-col(12);
					margin-bottom: 60px;

					.card {
						align-items: center;
						border: none;
						border-radius: 20px;

						.card-image {
							@include media-breakpoint-up(md) {
								@include make-col(6);
							}

							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}

							@include media-breakpoint-up(xl) {
								@include make-col(6);
							}
						}

						.card-body {
							padding: 30px;

							@include media-breakpoint-up(md) {
								@include make-col(6);
							}

							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}

							@include media-breakpoint-up(xl) {
								@include make-col(6);
							}

							.card-caption {
								.card-title {
									@include font-size($h1-font-size);
									color: $green;
									text-shadow: 3px 3px 0 rgba($black, 1);
								}
							}

							.card-buttons {
								.card-btn {
									@extend .btn-primary;
								}
							}
						}
					}

					&:nth-child(even) {
						.card {
							flex-direction: row-reverse !important;
						}
					}
				}
			}
		}
	}

	// icon-collection
	.icon-collection {
		margin-top: 30px;

		&.grid {
			.grid-items {
				margin-bottom: 0;

				@include media-breakpoint-down(sm) {
					margin-left: -5px;
					margin-right: -5px;
				}

				.item {
					@include make-col-auto();
					padding-left: 5px;
					padding-right: 5px;

					.card {
						max-width: 175px;

						@include media-breakpoint-down(sm) {
							max-width: 160px;
						}

						border: none;
						border-radius: 0;
						background: none;

						.card-image {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100px;
							height: 100px;
							margin: 0 auto;
							background-color: $orange;
							border-radius: 100%;
							color: $white;
							font-size: 50px;
							text-align: center;
							transition: 0.5s;
						}

						.card-body {
							margin-top: 1rem;
							padding: 0;

							.card-caption {
								color: $white;

								.card-title {
									margin-bottom: 0;
									font-size: 18px;

									&:hover {
										color: inherit;
									}
								}

								.card-subtitle,
								.card-description {
									display: none;
								}
							}
						}


						&[href]:hover {
							.card-image {
								background-color: $yellow;
							}

							.card-body {
								.card-caption {
									.card-title {
										color: $yellow;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 360px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 360px;
			max-height: 720px;
			padding: unset; // overide

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.3);
			}

			.owl-container {
				z-index: 1;
				position: relative;

				.owl-caption {
					.owl-title {
						@include font-size($h1-font-size);
						text-shadow: 6px 6px 0 rgba($black, 1);
					}

					.owl-description {
						max-width: 750px;
						margin: 0 auto;
					}

					.owl-btn {
						@extend .btn-primary;
					}
				}
			}
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
	text-align: center;

	h2 {
		color: $yellow;
	}

	// tiles-collection
	.tiles-collection {
		margin: 30px 0;

		&.grid {
			.grid-items {
				margin: 0 -10px -20px -10px;

				.item {
					margin-bottom: 20px;
					padding-left: 10px;
					padding-right: 10px;

					.card {
						border-radius: 10px;
						border: none;

						.card-img-overlay {
							top: auto;
							padding: 0;
							background: none;

							.card-caption {
								display: flex;
								flex-direction: column;
								justify-content: flex-end;
								margin-top: auto;
								padding: 15px;
								background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%);

								.card-title {
									@include font-size($h3-font-size);
									line-height: 0.9;

									@include media-breakpoint-up(xl) {
										font-size: 36px;
									}

									&:hover {
										color: $yellow;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// location-section
// =========================================================================
.location-section {
	@include media-breakpoint-down(md) {
		margin-top: calc(6vh + 165px);
	}

	min-height: 600px;
	background: url('/images/luchtfoto.jpg') no-repeat center center rgba($green, 0.1);
	background-size: cover;

	.wysiwyg {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 450px;
		margin: 6vh 0 6vh auto;
		padding: 15px 30px;
		background-color: $green;
		border-radius: 100%;
		box-shadow: 6px 6px 0 rgba($black, 1);
		color: $white;
		text-align: center;
		aspect-ratio: 1;

		@include media-breakpoint-down(md) {
			margin: -165px auto 0 auto;
		}

		h2 {
			color: $yellow;
		}

		h3,
		h4 {
			color: $white;
		}

		.btn {
			max-width: 260px;

			&:hover {
				border-color: $yellow;
				background-color: $yellow;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
