// header
.header {
	background-color: $white;

	&.sticky {
		.header-top {
			z-index: 998;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			box-shadow: $shadow;
		}
	}

	// header-top
	.header-top {
		padding: 15px 0;
		background-color: $white;

		@include media-breakpoint-up(xl) {
			padding: 15px;
		}

		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// header-bottom
	.header-bottom {
		.container-holder {
			align-items: flex-end;

			>.column {
				&.one {
					padding-right: 15px !important;
					@include make-col(12);

					@include media-breakpoint-up(xl) {
						@include make-col(5);
					}
				}

				&.two {
					display: none;
					padding-left: 15px !important;
					@include make-col(12);

					@include media-breakpoint-up(xl) {
						@include make-col(7);
					}

					@include media-breakpoint-up(sm) {
						display: flex;
					}
				}
			}

			.heading {
				@include font-size($h2-font-size);
				margin-bottom: 30px;
				color: $orange;
				line-height: 0.9;
				text-align: center;
				font-family: $font-family-secondary;

				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			padding: 6px 15px 6px 2px;
			border: 1px solid transparent;
			background-color: $orange;
			border-radius: 10px;
			color: $white;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			font-family: $font-family-secondary;
			box-shadow: 2px 2px 0 rgba($black, 1);
			transition: 0.5s;

			i {
				width: 30px;
				font-size: 18px;
				text-align: center;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background-color: $green;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: relative;
		width: 280px;
		margin: 0 auto;
		padding-top: 65px;

		@include media-breakpoint-up(xl) {
			margin: 0 0 -85px auto;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			background: url('/images/logo-ears.svg') no-repeat;
			background-size: 115px 62px;
			display: block;
			height: 62px;
			transition: 0.35s;
		}

		&::before {
			left: 36px;
			width: 62px;
			transform-origin: 100% 100%;
		}

		&::after {
			left: 98px;
			width: 53px;
			background-position: -62px 0;
			transform-origin: 0 100%;
		}

		&:hover::before {
			transform: rotate(-20deg);
		}

		&:hover::after {
			transform: rotate(20deg);
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						color: $green;
						font-size: 18px;
						line-height: 1;
						font-family: $font-family-secondary;

						&:hover {
							color: $orange;
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 16px;

							.nav-link {
								color: $green;

								&:hover {
									color: $orange;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				align-items: center;

				>.nav-item {
					margin-right: 5px;
					font-size: 18px;
					font-weight: 400;
					line-height: 1;
					font-family: $font-family-secondary;

					&:first-of-type {
						a {
							&::before {
								content: '\f015';
								font-family: $font-awesome;
								font-weight: 900;
							}

							.menu-title {
								display: none;
							}
						}

						@include media-breakpoint-down(lg) {
							display: none;
						}
					}

					>.nav-link {
						color: $green;

						&:hover {
							color: $orange;
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						margin-top: 0;
						padding: 10px;

						.nav-item {
							font-size: 16px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	// global-book-button
	div.global-book-button {
		margin-left: 15px;

		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-secondary;
					min-width: auto !important;
					padding: 6px 1rem !important;
					box-shadow: 3px 3px 0 rgba($black, 1);
					text-shadow: none !important;

					i {
						font-size: 18px;

						@include media-breakpoint-up(sm) {
							display: none;
						}
					}

					.list-item-title {
						@include media-breakpoint-down(xs) {
							display: none;
						}
					}

					@include media-breakpoint-down(lg) {
						font-size: 14px !important;
						padding: 6px 15px !important;
						box-shadow: 2px 2px 0 rgba($black, 1);
					}

					&:hover {
						border-color: $orange;
						background-color: $orange;
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		.logo {
			@include media-breakpoint-up(xl) {
				width: 447px;
				margin: 0 0 -150px auto;
				padding-top: 105px;

				&::before,
				&::after {
					background-size: 183px 99px;
					height: 99px;
				}

				&::before {
					left: 59px;
					width: 99px;
				}

				&::after {
					left: 158px;
					width: 84px;
					background-position: -99px 0;
				}
			}
		}

		.header-bottom {
			.container {
				max-width: 1920px;

				.mini-search-book {
					display: block;
					margin-bottom: 30px;

					.heading {
						margin: 0 auto 5px auto;

						@include media-breakpoint-down(lg) {
							display: block;
						}

						@include media-breakpoint-up(xl) {
							max-width: 520px;
						}
					}
				}
			}
		}
	}
}
